import { Toast } from "flowbite-react";
import { useEffect, useState } from "react";
import { CautionCircle } from "./icons";

export const CustomToast = ({ children }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const hide = localStorage.getItem("uploaderP_u");
    if (!hide && new Date(hide) < new Date()) {
      setShow(true);
    }
  }, []);

  const handleDismiss = () => {
    const hide = new Date();
    hide.setMonth(hide.getMonth() + 1);
    localStorage.setItem("uploaderP_u", hide.toISOString());
    setShow(false);
  };

  return (
    <>
      {show && (
        <div className="fixed top-4 right-4 z-50">
          <Toast>
            <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200">
              <CautionCircle />
            </div>
            <div className="ml-3 text-sm font-normal">{children}</div>
            <button onClick={handleDismiss}>
              <Toast.Toggle />
            </button>
          </Toast>
        </div>
      )}
    </>
  );
};
