import { useEffect, useState } from "react";
import { CustomToast } from "../customToast";
import { C_LEGAL_DOCUMENTS, GENERAL } from "../../constants/types";

export const OutdatedFiles = ({ data, formType }) => {
  const { general, legal_documents } = data?.[0] || {};
  const oneYearAgo = new Date().getTime() - 365 * 24 * 60 * 60 * 1000;
  const [count, setCount] = useState();

  const filter = (data) => {
    return data ? data.filter((data) => data.createdAt <= oneYearAgo) : [];
  };

  useEffect(() => {
    if (general && formType === GENERAL) {
      const flattenedData = [general].map((file) => Object.values(file));

      const filteredFiles = filter(flattenedData[0]);

      setCount(filteredFiles.length);
    } else if (legal_documents && formType === C_LEGAL_DOCUMENTS) {
      const extractedItems = Object.values(legal_documents).flatMap((obj) =>
        Object.values(obj)
      );

      const filteredFiles = filter(extractedItems);
      setCount(filteredFiles.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [general, legal_documents]);

  return (
    <>
      {count > 0 && (
        <CustomToast>
          {count} files uploaded over a year ago; delete if unnecessary.
        </CustomToast>
      )}
    </>
  );
};
