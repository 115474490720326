import { useContext, useEffect, useState } from "react";
import Walkthrough from "../../components/walkthrough/walkthrough";
import {
  Back,
  Last,
  Next,
  Skip,
  Title,
  ValidateRun,
} from "../../components/walkthrough/settings";
import { Card, Spinner } from "flowbite-react";
import CardList from "./cardList";
import CopyClipboard from "./copyClipboard";
import { Sort } from "../../utilities/utilityService";
import { DeleteModal } from "./deleteModal";
import { StorageContext } from "../../components/file-uploader/uploadSection";
import { usePaginate } from "../../hooks/usePaginate";
import { C_GENERAL, C_LEGAL_DOCUMENTS } from "../../constants/types";

export const HistoryCard = (props) => {
  const [data, setData] = useState();
  const { legalCategory, isLoading } = useContext(StorageContext);
  const { showingItems, PaginateBtn } = usePaginate(data, legalCategory);

  useEffect(() => {
    if (props.data[0] && props.data[0][props.formType]) {
      if (props.formType === C_LEGAL_DOCUMENTS) {
        setData(props.data[0][props.formType][legalCategory]);
      } else if (props.formType === C_GENERAL) {
        setData(props.data[0][props.formType]);
      } else {
        setData(props.data[0][props.formType]);
      }
    }
  }, [props.data, props.formType, legalCategory]);

  return (
    <div className="w-full mt-10">
      <Walkthrough
        run={ValidateRun("fileUploadTutorial")}
        tutorialStatus={"fileUploadTutorial"}
        walkthSteps={[
          {
            disableBeacon: true,
            target: ".st-1",
            title: <Title>History</Title>,
            content: "Last file upload detials.",
            offset: -10,
            locale: {
              next: <Next />,
              skip: <Skip />,
            },
          },
          {
            disableBeacon: true,
            title: <Title>Upload Form</Title>,
            offset: -50,
            target: ".st-2",
            content: "Verify with your email address and upload.",
            locale: {
              back: <Back />,
              last: <Last />,
            },
          },
        ]}
      >
        <Card className="flow-root">
          <ul className="st-1 divide-y divide-gray-200 dark:divide-gray-700">
            {data && !isLoading ? (
              props.formType === C_LEGAL_DOCUMENTS ||
              props.formType === C_GENERAL ? (
                Sort(data, props.filter)
                  .slice(0, showingItems)
                  .map((value, index) => {
                    return (
                      value.file && (
                        <li key={index} className="py-3 sm:py-4">
                          <CardList value={value} />
                          <div className="flex justify-between">
                            <CopyClipboard
                              data={value}
                              formType={props.formType}
                              legalCategory={legalCategory ? legalCategory : ""}
                              customStyle="mt-4"
                            />
                            <DeleteModal
                              value={value}
                              category={legalCategory}
                            />
                          </div>
                        </li>
                      )
                    );
                  })
              ) : (
                <CardList value={data} />
              )
            ) : isLoading ? (
              <Spinner />
            ) : (
              "Nothing uploaded yet..."
            )}
          </ul>
        </Card>
        <div className="flex justify-center">
          {(props.formType === C_LEGAL_DOCUMENTS ||
            props.formType === C_GENERAL) && <PaginateBtn />}
        </div>
      </Walkthrough>
    </div>
  );
};
