import { Select } from "flowbite-react";
import { useLocation } from "react-router-dom";
import { HistoryCard } from "../../layouts/fileUploader/historyCard";
import { UploadForm } from "./uploadForm";
import { createContext, useContext, useEffect, useState } from "react";
import CopyClipboard from "../../layouts/fileUploader/copyClipboard";
import {
  FilterOptions,
  LegalCategories,
} from "../../helpers/fileUploader/objects";
import {
  C_DIVIDEND_FEED,
  C_GENERAL,
  C_LEGAL_DOCUMENTS,
  C_TRADING_HOURS,
} from "../../constants/types";
import { UploadContext } from "../../context/UploadProvider";
import ConditionalRender from "../../helpers/fileUploader/condtRender";
import FormType from "../../helpers/fileUploader/formType";
import { OutdatedFiles } from "../../layouts/fileUploader/outdatedFiles";

export const StorageContext = createContext();

export const UploadSection = () => {
  const location = useLocation();
  const formType = FormType();
  const [legalCategory, setLegalCategory] = useState(
    LegalCategories()[0].value
  );
  const [filter, setFilter] = useState("all");
  const { data, isLoading } = useContext(UploadContext);

  const handleLegalCategory = (e) => {
    e.preventDefault();
    setLegalCategory(e.target.value);
  };

  // to reset the filter value on state change
  useEffect(() => {
    setFilter("all");
  }, [location]);

  return (
    <StorageContext.Provider value={{ legalCategory, isLoading }}>
      <ConditionalRender condition={[C_LEGAL_DOCUMENTS, C_GENERAL]}>
        <OutdatedFiles data={data} formType={formType} />
      </ConditionalRender>
      <div className="grid gap-2 grid-cols-1 lg:grid-cols-2 w-full ">
        <div className="w-full">
          <div className="flex gap-16">
            <h2 className="block mb-4 text-3xl font-bold tracking-tight text-secondary">
              Upload new
            </h2>

            <ConditionalRender condition={[C_LEGAL_DOCUMENTS]}>
              <Select
                id="category"
                required={true}
                defaultValue={LegalCategories()[0].value}
                onChange={handleLegalCategory}
              >
                <option value="" disabled>
                  Choose here
                </option>
                {LegalCategories().map((item, index) => {
                  return (
                    <option key={index} value={item.value}>
                      {item.category}
                    </option>
                  );
                })}
              </Select>
            </ConditionalRender>
          </div>

          <UploadForm formType={formType} />
        </div>
        <div className="w-full text-start">
          <div className="flex justify-between">
            <h2 className="block mb-4 text-3xl font-bold tracking-tight text-secondary">
              Upload details{" "}
              <ConditionalRender condition={[C_LEGAL_DOCUMENTS]}>
                <span className="bg-green-100 text-primary text-sm font-medium mr-2 px-2.5 py-0.5 rounded">
                  {legalCategory}
                </span>
              </ConditionalRender>
            </h2>
            <ConditionalRender condition={[C_DIVIDEND_FEED, C_TRADING_HOURS]}>
              <CopyClipboard data={data} formType={formType} />
            </ConditionalRender>

            <ConditionalRender condition={[C_GENERAL, C_LEGAL_DOCUMENTS]}>
              <Select
                id="category"
                defaultValue={"all"}
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
              >
                {FilterOptions().map((item, index) => {
                  return (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  );
                })}
              </Select>
            </ConditionalRender>
          </div>
          <HistoryCard data={data} formType={formType} filter={filter} />
        </div>
      </div>
    </StorageContext.Provider>
  );
};
